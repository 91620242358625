import React, { createContext, useCallback, useState, useContext } from 'react'
import { jwtDecode } from 'jwt-decode'

import api, { blobAPI, fileAPI } from '../services/api'
import { removeSession, checkSession } from '../services/session'
// import setNotifications from '../services/firebase'
import socket from '../services/socket'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [data, setData] = useState(useCallback(() => {
    const token = window.localStorage.getItem('clipei:token')
    if (token) {
      const user = window.localStorage.getItem('clipei:user')
      const parserdUser = JSON.parse(user)
      checkSession({ token }).then((response) => {
        if (!response.data) {
          window.localStorage.clear()
          socket.disconnect()
          window.location.href = '/'
          return {}
        }
      })
      const currentTime = new Date().getTime() / 1000
      if (currentTime > jwtDecode(token).exp) {
        const token = window.localStorage.getItem('clipei:token')
        removeSession({ sessionId: token })
        window.localStorage.clear()
        socket.disconnect()
        return {}
      }

      const type = window.localStorage.getItem('clipei:type')

      api.defaults.headers.authorization = `Bearer ${token}`
      api.defaults.headers.type = type
      fileAPI.defaults.headers.authorization = `Bearer ${token}`
      blobAPI.defaults.headers.authorization = `Bearer ${token}`
      // setNotifications({ userId: parserdUser.id, token })
      socket.auth.authorization = token
      socket.connect()
      socket.on('disconnect', (reason) => {
        if (reason === 'io server disconnect') { socket.connect() }
      })

      return { user: parserdUser, type, token }
    }
    return {}
  }), [api, fileAPI, blobAPI, socket])

  const singIn = useCallback(async ({ username, password }) => {
    const response = await api.post('session', { username, password })
    if (response.data.status === 'error') { throw response.data.message }
    const { token, user } = response.data
    const { type } = response.data.user
    window.localStorage.setItem('clipei:token', token)
    window.localStorage.setItem('clipei:type', type)
    window.localStorage.setItem('clipei:user', JSON.stringify(user))
    window.localStorage.setItem('clipei:sidebar', 'false')
    window.localStorage.setItem('clipei:nightmode', 'true')
    api.defaults.headers.authorization = `Bearer ${token}`
    fileAPI.defaults.headers.authorization = `Bearer ${token}`
    blobAPI.defaults.headers.authorization = `Bearer ${token}`
    // setNotifications({ userId: user.id, token })
    socket.auth.authorization = token
    socket.connect()
    socket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') { socket.connect() }
    })
    setData({ user, type })
  }, [api, fileAPI, blobAPI, socket])

  const signOut = useCallback(() => {
    const token = window.localStorage.getItem('clipei:token')
    removeSession({ sessionId: token })
    window.localStorage.clear()
    socket.disconnect()
    setData({})
  }, [api, socket, window.localStorage])

  return (<AuthContext.Provider value={{ user: data.user, type: data.type, token: data.token, singIn, signOut }}>{children}</AuthContext.Provider>)
}

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) { throw new Error('useAuth só pode ser usando dentro do AuthProvider') }
  return context
}
