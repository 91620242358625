import React, { createContext, useState, useContext, useCallback } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { dark, light } from '../theme'

const ThemeContext = createContext({})

export const AppThemeProvider = ({ children }) => {
  const [data, setData] = useState(useCallback(() => {
    const localDarkmode = window.localStorage.getItem('clipei:nightmode')

    if (localDarkmode === null) {
      return { theme: dark, dark: true }
    } else {
      const darkmode = localDarkmode === 'true'
      return { theme: (darkmode ? dark : light), dark: darkmode }
    }
  }), [])

  const handleDark = useCallback(() => {
    window.localStorage.setItem('clipei:nightmode', !data.dark)
    setData({ theme: (!data.dark ? dark : light), dark: !data.dark })
  }, [data])

  return (
    <ThemeContext.Provider value={{ theme: data.theme, handleDark, dark: data.dark }}>
      <ThemeProvider theme={data.theme}>
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  const context = useContext(ThemeContext)
  if (!context) { throw new Error('useTheme  só pode ser usando dentro do ThemeProvider') }
  return context
}
