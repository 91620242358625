import React from 'react'
import { SwipeableDrawer as MuiDrawer, AppBar, Toolbar, Typography, Box, IconButton, Link } from '@mui/material'
import { Menu as MenuIcon, ChevronLeft } from 'react-feather'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled } from '@mui/material/styles'
import { NavLink } from 'react-router-dom'

import { useTheme } from '../../hooks'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`
  }
})

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  width: 555,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const Loading = () => {
  const { dark } = useTheme()

  const smScreen = useMediaQuery(theme => theme.breakpoints.up('sm'))

  const open = (window.localStorage.getItem('clipei:sidebar') === 'true')

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component='static'>
        <Toolbar>
          {!open &&
            <IconButton edge='start' sx={{ mr: 2 }} color='inherit'>
              <MenuIcon size={25} />
            </IconButton>}

          <IconButton color={dark ? 'primary' : 'secondary'} aria-label='Voltar uma pagina' edge='start'>
            <ChevronLeft size={25} />
          </IconButton>

          <Typography component='h1' variant='h6' color='inherit' noWrap sx={{ display: 'flex', flexDirection: 'row', textDecoration: 'none' }}>
            <Link noWrap color='inherit' component={NavLink} to='/'>
              <img src='/clipei.png' alt='Clipei' style={{ height: '30px' }} />
            </Link>
          </Typography>

          <IconButton color='inherit' aria-label='open drawer' edge='start' sx={{ ml: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>

        </Toolbar>
      </AppBar>
      <Drawer variant={(smScreen) ? 'permanent' : 'temporary'} open={open}>
        <DrawerHeader>
          <IconButton color='inherit'>
            <MenuIcon size={25} />
          </IconButton>
        </DrawerHeader>
      </Drawer>

      <Box component='main' sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', pt: 8, m: 1 }} />
    </Box>
  )
}

export default Loading
