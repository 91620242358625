import { createTheme } from '@mui/material/styles'
import { red } from '@mui/material/colors'

const envColors = {
  production: {
    primary: '#d49c34',
    lightPrimary: '##fcb900',
    secondary: '#303030',
    lightSecondary: '#575757'
  },
  development: {
    primary: '#d49c34',
    secondary: '#303030'
  }
}

const colors = envColors[process.env.REACT_APP_ENV]

const theme = createTheme({})

const customTheme = createTheme(theme, {
  paper: {
    padding: theme.spacing(1),
    overflow: 'auto',
    height: '100%'
  },
  paperZero: {
    padding: theme.spacing(0),
    overflow: 'auto',
    height: '100%'
  },
  nenhumBox: {
    alignItens: 'center',
    textAlign: 'center',
    height: '90%',
    justifyContent: 'center',
    paddingBottom: 20
  },
  dragOrClick: {
    padding: '16px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    height: '95%',
    justifyContent: 'center',
    alignItens: 'center',
    border: '1px dashed #ccc',
    textAlign: 'center'
  }
})

const dark = createTheme({
  ...customTheme,
  palette: {
    mode: 'dark',
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    error: {
      main: red.A400
    },
    white: {
      main: '#fff'
    }
  }
})

const light = createTheme({
  ...customTheme,
  palette: {
    mode: 'light',
    primary: {
      main: colors.primary
    },
    secondary: {
      main: colors.secondary
    },
    error: {
      main: red.A400
    }
  }
})

export { dark, light, colors }
