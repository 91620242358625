const userNavigationTypes = {
  all: ['admin', 'user'],
  admin: ['admin']
}

const checkUserType = (routeTypes, userTypes) => {
  if (userNavigationTypes[routeTypes].indexOf(userTypes) > -1) { return true }
  return false
}

const userArrayTypes = [
  ['admin', 'Administrador'],
  ['user', 'Usuário']
]

const userTypes = {
  admin: 'Administrador',
  user: 'Usuário'
}

export {
  checkUserType,
  userArrayTypes,
  userTypes
}
