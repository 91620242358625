import api from './api'

const checkSession = async ({ token }) => {
  return await api.get(`/session/${token}`)
}

const removeSession = async ({ sessionId }) => {
  return await api.delete(`/session/${sessionId}`)
}

export { checkSession, removeSession }
