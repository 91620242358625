import React from 'react'
import { Tooltip, Typography, IconButton, Menu, MenuItem, ListItemIcon, Divider } from '@mui/material'
import { User, AtSign, Sun, Moon, LogOut, Key, MoreVertical } from 'react-feather'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { useNavigate } from 'react-router-dom'

import { useAuth, useTheme } from '../hooks'
import packa from '../../package.json'

const MenuComp = ({ version }) => {
  const { handleDark, dark } = useTheme()
  const { signOut, user } = useAuth()
  const navigate = useNavigate()

  return (
    <PopupState variant='popover' popupId='popup-menu'>
      {(popupState) => (
        <>
          <IconButton color='inherit' aria-label='Sair' {...bindTrigger(popupState)}>
            <MoreVertical size={15} />
          </IconButton>
          <Menu onClose={() => popupState.close()} {...bindMenu(popupState)}>
            <MenuItem disabled>
              <ListItemIcon>
                <User size={15} />
              </ListItemIcon>
              <Typography color='inherit'>
                {user.name}
              </Typography>
            </MenuItem>

            <MenuItem disabled>
              <ListItemIcon>
                <AtSign size={15} />
              </ListItemIcon>
              <Typography color='inherit'>
                {user.username}
              </Typography>
            </MenuItem>

            <Divider />

            <MenuItem onClick={handleDark}>
              <ListItemIcon>
                <Tooltip title={`FRONTEND_${packa.version} / BACKEND_${version}`} placement='right' enterDelay={1000}>
                  {dark ? <Sun size={15} /> : <Moon size={15} />}
                </Tooltip>
              </ListItemIcon>
              {dark ? 'Modo claro' : 'Modo escuro'}
            </MenuItem>

            <MenuItem
              onClick={() => {
                popupState.close()
                navigate('/trocar-senha')
              }}
            >
              <ListItemIcon>
                <Key size={15} />
              </ListItemIcon>
              Trocar senha
            </MenuItem>
            <MenuItem onClick={signOut}>
              <ListItemIcon>
                <LogOut size={15} />
              </ListItemIcon>
              Sair
            </MenuItem>
          </Menu>
        </>)}
    </PopupState>
  )
}

export default MenuComp
